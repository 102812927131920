<template>
    <div>
     <!-- background:#FFF !important;-webkit-box-shadow: 0 0 1px rgba(0,0,0,.14),0 1px 3px rgba(0,0,0,.28) !important;box-shadow: 0 0 1px rgba(0,0,0,.14),0 1px 1px rgba(0,0,0,.28) !important; -->
        <nav class="navbar fixed-top navbar-expand-lg border-bottom" style="background:#fff !important">
            <div class="text-center m-0 p-2 details-asfa font-weight-bold" style="position:absolute;z-index:9990;top:0;left:0;right:0;height:auto;background:#26388d;color:#FFF">
              Compte administrateur
            </div>
           <div class="container" style="margin-top:30px">
           <a class="navbar-brand text-center" href="#">
             <div class="d-flex">
                <img src="../../../assets/logo.png" height="55px" alt=""> <br>         
             </div>
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img" focusable="false"><title>Menu</title><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav navbar-top mr-auto text-center">
                <li class="nav-item">
                  <a href="" class="nav-link"></a>
                </li>
            </ul>
            <ul class="navbar-nav navbar-top">
              <li class="nav-item">
                <router-link :to="{ name : 'admin.station'}" class="nav-link">
                    Stations
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <router-link :to="{ name : 'admin.news'}" class="nav-link">
                    Actualités
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'admin.post'}" class="nav-link">
                    Conseils
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'admin.newsletters'}" class="nav-link">
                    Newsletters
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'job'}" class="nav-link">
                    Dépôt de GAZ
                </router-link>
              </li>
               <li class="nav-item dropdown">
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">
                    Carrière
                </a>
                <div class="dropdown-menu shadow-sm border-bottom" style="border:0;font-size:12px" aria-labelledby="dropdownMenuButton">
                        <router-link :to="{ name : 'admin.job'}" class="dropdown-item font-weight-bold">
                           Nouvelle offre
                        </router-link>
                        <router-link :to="{ name : 'admin.appsp'}" class="dropdown-item font-weight-bold">
                            Candidature spontanée
                        </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
        </nav>
        <div style="margin-top:110px;">
          <router-view></router-view>
        </div>
  </div>
</template>

<script>
export default {
    name : "Main",
    data(){
      return {
          user : JSON.parse(localStorage.getItem('asfacisite__api__Oauth')),
      }
    }
}
</script>

<style lang="scss">
@import '~summernote/dist/summernote-bs4.css';
.h-full{
    height:calc(100vh - 110px);
    overflow: auto;
    background:#fff;
}

.h-full-w35{
    width: 35%;
    height: 100%;
    &:hover{
        overflow: auto
    }
}

.h-full-w400px{
    width: 400px;
    height: 100%;
    overflow: hidden;
    &:hover{
        overflow: auto
    }

    form{
        height: 100%;
        position: relative;
        &:hover{
            overflow: auto
        }
    }
}

.h-full-w65{
    width: 65%;
    height: 100%;
    overflow: hidden;
    &:hover{
        overflow: auto
    }
}

.h-full-rw400px{
    width:calc(100% - 400px);
    height: 100%;
    overflow: hidden;
    &:hover{
        overflow: auto
    }
}

.h-full-h85{
    width: 100%;
    height: 90%;
    overflow: hidden;
    &:hover{
        overflow: auto
    }
}


.h-full-h15{
    width: 100%;
    height: 10%;
}
</style>