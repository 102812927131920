<template>
<div id="app">
        <div>
          <router-view></router-view>
        </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted(){
    
  },
}
</script>


<style lang="scss">
@font-face {
  font-family: "Comfortaa";
  src: url("./assets/comfortaa/Comfortaa-Bold.ttf");
  src: url("./assets/comfortaa/Comfortaa-Light.ttf");
  src: url("./assets/comfortaa/Comfortaa-Regular.ttf");
}

*{
  outline: none;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  /* font-family: 'Lato', sans-serif; */
  /* font-size: 13px !important; */
  background: #fff;
  color:#000; /*044a67*/
  font-weight: 400;
  font-size: 14px;
}

  @import "vue-select/src/scss/vue-select.scss";
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    background: #fff;
    border: 1px solid #ccc;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
    min-height:50px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0;
  }

  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #394066;
  }

.router-link-exact-active{
  color:#000 !important;
  /* border-bottom : 2px solid #f7ea00; */
  background : #fdd835;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Comfortaa';
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  color:#000 !important;
  font-weight: bold;
  background: #fdd835 !important;
}

.nav-pills .nav-link{
  color:#000 !important;
}

.btn-site{
  background: #FFF;
  border: 2px solid #fdd835 !important;
  background: #fdd835 !important;
  color:#000 !important;
  border-radius : 20px !important;
  width: 160px;
  font-weight: bold;
}

.text-yellow{
  color : #fdd835;
}

.form-control{
  border:2px solid #eee !important;
}

.special-title{
  background: #fff;
  font-size: 30px;
  // padding:11px 21px;
  color:#000;
  font-weight: bold;
}

.text-blue{
  color : #26388d;
}
.navbar-top .nav-link{
    color:#000 ; /*044a67*/
    font-size: 13px;
    /* font-family: 'Abril Fatface'; */
    font-weight: 500;
}

  @media (max-width: 576px) { 
      .details-asfa{
        font-size:10px !important;
      }
      body{
        font-size: 12px !important;
      }
  }


.container-table{
    display: table;
    width: 100%;
    height:100%;
}

.absolute-layer{
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  /* background: #000; */
  z-index: 1;
}

.box-cell{
    display:table-cell;
    width:auto;
    height: auto;
    vertical-align: middle;
}

  .text-ellipsis{
     -o-text-overflow: ellipsis;   
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

  .text-wrapp{
    text-overflow: ellipsis;
    // white-space: nowrap !important;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      -o-text-overflow: ellipsis; 
      display: -webkit-box;
      width:150px;
  }

.box-img-flyer{
    width: 100%;
    height:350px;
    position: relative;
    .absolute-title{
      width: 100%;
      position: absolute;
      top:100px;
      text-align: center;
      font-size:60px;
    }
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

.container-table{
    display: table;
    width: 100%;
    height:100vh;
}

.box-cell{
    display:table-cell;
    width:auto;
    height: auto;
    vertical-align: middle;
}

</style>
