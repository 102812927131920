<template>
  <div style="background:#4368de" class="p-4 mt-5 text-white text-center">
        <h4>Devenir revendeur de GAZ DISTRICOM ?</h4>
        <p>Nous sommes à la recherche de personnes motivées pour développer notre réseau de distributeur de gaz.</p>
        <p class="mt-3">
            <button style="border: 2px solid white;" class="btn btn-lg btn-outline-light mt-3">Appelez nous au +225 06 180 091</button>
            <button class="btn btn-lg btn-outline-default text-white mt-3 ml-3">OU</button>
             <router-link :to="{name : 'gas'}" style="border: 2px solid white;" class="btn btn-lg btn-outline-light ml-3 mt-3">Renseignez le formulaire de demande</router-link>
        </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>