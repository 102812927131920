import Home from './components/Home.vue'
import Main from './components/Main.vue'
import Job from './components/Job.vue'
import JobContent from './components/JobContent.vue'
import Conseil from './components/Conseil.vue'
import Stations from './components/Stations.vue'
import Stationsmaps from './components/Stationsmaps.vue'
import Login from './components/layouts/admin/Login.vue'
import AdminPost from './components/layouts/admin/Post.vue'
import AdminMain from './components/layouts/admin/Main.vue'
import AdminStations from './components/layouts/admin/Stations.vue'
// import AdminPostList from './components/layouts/admin/Postlist.vue'
import AdminNews from './components/layouts/admin/News.vue'
import Districomoil from './components/layouts/activity/Districomoil.vue'
import Districomgaz from './components/layouts/activity/Districomgaz.vue'
import Districombtp from './components/layouts/activity/Districombtp.vue'
import AdminNewsletter from './components/layouts/admin/Newsletter.vue'
import AdminJob from './components/layouts/admin/Job.vue'
import AdminAppSp from './components/layouts/admin/Applicationsp.vue'
import Blogviewer from './components/layouts/Blogviewer.vue'
import About from './components/About.vue'
import Contact from './components/Contact.vue'
import Gaz from './components/Gaz.vue'
import Prestations from './components/Prestations.vue'


const routes = [
    { path: '*', redirect: '/districo/home'},
    { 
            path: '/districo/administrator/login', component: Login , name : "admin.login",
            beforeEnter : (to, from, next) => {
                let data = localStorage.getItem('districosite__api__Oauth') ? JSON.parse(localStorage.getItem('districosite__api__Oauth')) : null;
                if(data) 
                    next({name : 'admin.station'});
                else
                    next();
            }
    },
    { 
        path: '/administrator/layouts', component: AdminMain , name : "admin",
        children : [
            {path : 'post/conseil', component: AdminPost , name : "admin.post"},
            {path : 'station', component: AdminStations , name : "admin.station"},
            {path : 'news', component: AdminNews , name : "admin.news"},
            {path : 'newsletters', component: AdminNewsletter , name : "admin.newsletters"},
            {path : 'job', component: AdminJob , name : "admin.job"},
            {path : 'application/spontaneous/list', component: AdminAppSp , name : "admin.appsp"},
        ],
        beforeEnter : (to, from, next) => {
            let data = localStorage.getItem('districosite__api__Oauth') ? JSON.parse(localStorage.getItem('districosite__api__Oauth')) : null;
            if(!data) {
                next({name : 'admin.login',query : { next : location.href}});
            } else{
                next();
            }
        }
    },
    { path: '/districo', component: Main,children : [
            { path: 'home', component: Home , name : "home" },
            { path: 'into-our-stations/:city?', component: Stations , name : "stations"},
            { path: 'conseils', component: Conseil , name : "conseils" },
            { path: 'job', component: Job , name : "job" },
            { path: 'job/content/:code', component: JobContent , name : "job-c"},
            { path: 'about-us', component: About , name : "about" },
            { path: 'contact-us', component: Contact , name : "contact-us"},
            { path: 'open-gas-shop', component: Gaz , name : "gas"},
            { path: 'our-prestations', component: Prestations , name : "prestations"},
            { path: 'conseil/post/article/:id/:title' , component: Blogviewer, name : "blogviewer" },
            { path: 'stations/maps' , component: Stationsmaps, name : "maps" },
            { path: 'districom-oil' , component: Districomoil, name : "districomoil" },
            { path: 'districom-gaz' , component: Districomgaz, name : "districomgaz" },
            { path: 'districom-btp' , component: Districombtp, name : "districombtp" },
        ]
    }
];
export default routes;