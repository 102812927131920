const base_URL = 'https://apidistrico.districom-ci.net/public';
const BackendRouteLink =  {
        station_create : '/station/create', 
        station_get : base_URL+'/station/get', 
        station_search : base_URL+'/stations/search', 
        newsletter_add : base_URL+'/newsletter/add', 
        newsletter_get : '/newsletter/get', 
        news_create : '/news/create', 
        news_get : base_URL+'/news/get', 
        job_create : '/job/create', 
        job_get :'/job/get', 
        job_public_get :base_URL+'/public/job/get', 
        conseil_get :base_URL+'/public/conseil/get', 
        conseil_create :'/conseil/create',
        conseil_postcomment :base_URL+'/conseil/post/comment',
        conseil_getcomment :base_URL+'/conseil/get/comment',
        conseil_getsimular :base_URL+'/conseil/get/simular',
        application_sp :'/application/spontaneous/create',
        applicationsp_get :'/application/spontaneous/get',
        applicationoffer_create:'/application/offer/create',
        contactinfo:base_URL+'/contact/info',
}
export default BackendRouteLink;