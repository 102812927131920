<template>
    <div class="activity">
        <div class="col-sm-12 shadow-sm">
            <div class="row">
                <div class="col-sm-3">
                    <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <p class="m-0 text-white p-2" style="background:#000">
                                    <b>Actualités du groupe</b>
                                </p>
                            </div>
                        </div>
                </div>
                <div class="col-sm-9 p-3">
                    <div class="swiper-container activity">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item in news" :key="item.id">
                                <p class="m-0">
                                {{item.news}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="container">
        <div class="col-sm-12 mt-5 p-3">
                    <h5 class="text-left text-white p-2 w-50 bg-dark font-weight-bold"> Nos services et prestations</h5>
        </div>
        <div class="col-sm-12 p-3 text-center services">
            <div class="row">
                <div class="col-sm-3">
                    <router-link :to="{name : 'districomoil'}">
                    <img src="../../assets/mascotte-pers.jpg" height="160" alt="">
                    <h5 class="mt-3">Distribution de Carburant</h5>
                     </router-link>
                </div>
                <div class="col-sm-3">
                    <router-link :to="{name : 'districomgaz'}">
                        <img src="../../assets/bout-ensemble.png" style="height:160px" alt="">
                        <h5 class="mt-3">Distribution de GAZ</h5>
                    </router-link>
                </div>
                <div class="col-sm-3">
                    <router-link :to="{name : 'districomoil'}">
                        <img src="../../assets/camion-citerne-bleu.jpg" style="height:160px" alt="">
                        <h5 class="mt-3">Transport d'hydrocarbure</h5>
                    </router-link>
                </div>
                <div class="col-sm-3">
                     <router-link :to="{name : 'districombtp'}">
                        <img src="../../assets/casque.jpg" style="height:160px" alt="">
                        <h5 class="mt-3">BTP</h5>
                     </router-link>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
  import { Swiper, Navigation, Pagination,Autoplay, Scrollbar,A11y  } from 'swiper';
  import 'swiper/swiper-bundle.css';
import backendService from '../../backend.service';
  Swiper.use([Navigation, Pagination, Autoplay,Scrollbar,A11y ]);
export default {
  name: 'Activity',
  mixins : [],
  props: {},
  data(){
        return{
            news : []
        }
    },
  components: {
    //   Swiper,
    //   SwiperSlide,
    },
    mounted() {
         backendService.news_get().then((response)=>{
            this.news = response.data.data.lastnews;
            setTimeout(()=>{
                new Swiper('.swiper-container.activity', {
                    // Optional parameters
                    autoplay: {
                        delay: 5000,
                    },
                    speed: 500,
                    direction: 'horizontal',
                    loop: true,
                    // If we need pagination
                    pagination: {
                        el: '.swiper-pagination',
                    },

                    // Navigation arrows
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },

                    // And if we need scrollbar
                    scrollbar: {
                        el: '.swiper-scrollbar',
                    },
                });
            },3000);
        });
  }
}
</script>

<style scoped>
.test{
    border-top: 100px solid transparent;
    border-left: 100px solid  transparent;
    border-right: 100px solid  transparent;
    border-bottom: 100px solid  #000;

}
    .activity{
        /* background: #fff; */
    }

    .services a{
        text-decoration : none !important; color: #000 !important
    }

    .box{
        min-height: 500px;
        width: 100%;
        background: #fff;
        color:#000;
    }
    .box:hover{
        /* border-bottom:5px solid #490; */
        cursor: pointer;
    }

    .box .img{
        height: 250px;
        width: 100%;
        display: block;
        background: #000;
    }

    .box-s{
        min-height: 300px;
        width: 100%;
        color:#FFF;
        background: #099548;
    }

    .box h1, .box-s h1{
          /* font-weight: 600; */
          font-size: 2rem;
    }

    .activity .img-activity{
        height: auto;
        width: 100%;
        background: #000;
    }

    .img-dg{
        /* background:url('../../assets/msc_5251_2x.jpg') no-repeat scroll  50% 10% / 100% 150%; */
    }

    .box img{
         width: 100%;
         height: 100%;
         object-fit: cover;
         vertical-align :middle;
    }

    
    .activity-describe{
        color:#FFF;
        background: #099548;
    }

    .swiper-container {
      width: 100%;
      height: 60px;

    }
    .swiper-slide {
      text-align: center;
      font-size: 14px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

</style>