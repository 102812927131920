<template>
    <div class="bout pb-5" style="background:#dfe1ed">
        <div class="p-3 container">
            <div class="col-sm-12 text-center mt-5">
                <h3>DISTRICOM GAZ</h3>
                <p>Les prix des consignes</p>
            </div>

            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-4">
                            <div class="w-100 text-center  shadow-sm border bg-light">
                                    <p class="p-3 border-bottom font-weight-bold">Bouteille B6</p>
                                    <img class="img-fluid" style="height:100px" src="../assets/b6.jpeg" alt="">
                                    <p class="p-4" style="font-size:17px">Bouteille économique <br><br><br></p>
                                    <p class="border-top m-0 p-3 font-weight-bold text-blue"> Prix de la consigne : 12 500 FCFA </p>

                            </div>
                    </div>
                    <div class="col-sm-4">
                             <div class="w-100 text-center  shadow-sm border bg-light">
                                    <p class="p-3 border-bottom font-weight-bold">Bouteille B12</p>
                                    <img class="img-fluid" style="height:100px" src="../assets/b12.jpeg" alt="">
                                    <p class="p-4" style="font-size:17px">Bouteille économique aux usages multiples <br><br> </p>
                                    <p class="border-top m-0 p-3 font-weight-bold text-blue"> Prix de la consigne : 23 000 FCFA </p>
                            </div>
                    </div>
                    <div class="col-sm-4">
                             <div class="w-100 text-center shadow-sm border bg-light">
                                    <p class="p-3 border-bottom font-weight-bold">Bouteille B38</p>
                                    <img class="img-fluid" style="height:100px" src="../assets/b38.jpeg" alt="">
                                    <p class="p-4" style="font-size:17px">Bouteille pour les consommations importantes et les utilisations professionnelles</p>
                                    <p class="border-top m-0 p-3 font-weight-bold text-blue"> Prix de la consigne : 50 000 FCFA </p>

                            </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>