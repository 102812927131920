<template>
    <div class="home">
        <Slider></Slider>
        <div class="" style="background:#fff">
          <Activity></Activity>
        </div>
        <Implantation></Implantation>
        <GazOpen></GazOpen>
        <Bout></Bout>
        <Estimation></Estimation>
        <Dg></Dg>
        <News></News>
        <Partenaire></Partenaire>
    </div>
</template>

<script>
import Slider from './layouts/Slide' 
import Dg from './layouts/Dg' 
import Activity from './layouts/Activity' 
import Estimation from './layouts/Estimation'
import Partenaire from './layouts/Partenaire'
import Implantation from './layouts/Implantation'
import GazOpen from './layouts/Gazopen'
import News from './layouts/News'
import Bout from './Bout'
export default {
  name: 'Home',
  components: {
    Bout,
    Slider,
    GazOpen,
    Dg,
    Activity,
    Estimation,
    Partenaire,
    Implantation,
    News
  },
  data(){
    return {
        cities : []
    }
  }
}
</script>


<style scoped>

</style>
