<template>
  <div style="background:#fff">
      <!-- <div class="box-img-flyer">
        <img src="../assets/slider/bg_04.jpg"  alt="">
        <div class="absolute-title text-white">
          <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                <h1>Nos prestations !</h1>
                <p style="font-size:16px">Découvrez nos produits et services...</p>
          </div>
        </div>
      </div> -->

      <div class="col-sm-8 offset-sm-2 mt-5 mb-5" style="font-size:16px;text-align : justify">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12 mt-5">
                <p>Retrouvez tous nos produits et services disponibles dans toutes les stations de la compagnie.</p>
              </div>
              <div class="col-sm-12">
                <div class="special-title">Services aux Professionnels</div>
                <div class="swiper-container stations mt-3">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide"> 
                                      <img src="../assets/cuve-simple.jpg" alt="">
                                </div>
                            </div>
                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                </div>

                <div class="col-sm-12 shadow-sm mt-3 bg-white p-3">
                  <p class="text-blue font-weight-bold">Nos produits</p>
                  <p class="font-weight-bold">Produits pétroliers et dérivés</p>
                  <p>Chez DISTRICOM, le client est au centre de nos actions et constitue notre priorité. Nous souhaitons être votre partenaire privilégié en matière de produits pétroliers de votre entreprise, dans un cadre contractuel. </p>
                  <p>C’est pourquoi, nous vous proposons une large gamme de produits pétroliers et dérivés pour tous vos besoins :</p>
                  <p class="pl-3">
                    – Super Sans Plomb <br>
                    – Gasoil <br>
                    – Pétrole Lampant <br>
                    - DDO (Distillate Diesel Oil) <br>
                    - Fuel oil <br>
                    - Bitume <br>
                    - Lubrifiants et graisse <br>
                    - GAZ en vrac
                  </p>
                  <p>Nous proposons également du gaz butane en vrac à la demande pour vos besoins d’exploitation..</p>
                  <p class="text-blue font-weight-bold">Nos services</p>
                  <p>Notre offre de services pour les professionnels comprennent :</p>
                  <p class="pl-3">
                    – Livraison sur site dans le respect des normes de sécurité <br>
                    – Installation d’équipements de stockage et de distribution (cuve à gaz, citerne à carburant) <br>
                    – Formation liée à la gestion d’un dépôt d’hydrocarbure <br>
                    – Entretien et maintenance des installations
                  </p>
                  <p>Chez DISTRICOM, nous vous garantissons une qualité et une disponibilité constante de nos produits. Avec nous, vous avez l'assurance d'un caburant de qualité.</p>
                </div>

                <div class="special-title mt-5">Services aux Particuliers </div>
                <div class="swiper-container stations mt-3">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                      <img src="../assets/bg3.jpg" alt="">
                                </div>
                                <div class="swiper-slide">
                                      <img src="../assets/slider/bg_04.jpg" alt=""> 
                                </div>
                                <div class="swiper-slide">
                                      <img src="../assets/20190315_111623.jpg" alt=""> 
                                      
                                </div>
                            </div>
                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                </div>

                <div class="col-sm-12 shadow-sm mt-3 bg-white p-3">
                    <p class="text-blue font-weight-bold">Nos produits</p>
                    <p class="pl-3 font-weight-bold">1.	Carburants</p>
                    <p>Soucieux de répondre aux besoins des clients, DISTRICOM propose des produits et services de qualité. Les produits Carburants (super sans plomb, gasoil etc…) sont disponibles pour les clients dans nos stations-service, présente sur toute l’étendue du territoire ivoirien. Toutes nos stations-services sont ouvertes 24h/24 et 7j/7. Notre capacité de stockage accrue et notre fréquence de rotation nous permettent de ne jamais être en rupture. </p>
                    <p>Nous vous fournissons du carburant de qualité, dont les spécifications sont conformes aux normes internationales. </p>
                    <p>Nous démontrons continuellement notre professionnalisme et assurons la qualité de nos services à travers notre important réseau de stations-services. </p>
                    <p class="pl-3 font-weight-bold">2.	Lubrifiants</p>
                    <p> <b>DISTRICOM OIL </b>DISTRICOM s’engage à répondre à tous vos besoins en matière de lubrifiants et à vous apporter le support technique et commercial nécessaire. Nous offrons une gamme complète de lubrifiants destinés aux automobilistes, aux industriels, ainsi que des huiles et graisse à usage spécial pour diverses utilisations. Les produits que nous commercialisons sont en conformité avec les cahiers des charges les plus exigeants des constructeurs automobiles et sont disponibles à travers notre réseau de stations-services.
                    </p>
                    <p class="pl-3 font-weight-bold">3.	Gaz butane</p>
                    <p>DISTRICOM commercialise le gaz butane de marque DISTRICOM GAZ sous trois formats (Bouteille de 6 kg, Bouteille de 12,5 kg et Bouteille de 38 kg).</p>
                    <p class="text-blue font-weight-bold">Nos services</p>
                    <p>Les stations-services DISTRICOM OIL proposent plusieurs services à sa clientèle, </p>
                    <p class="pl-3">
                      -	<b>Services payants :</b> Lavage, Vidange et Graissage <br>
                      -	<b>Services gratuits : </b> nettoyage de parebrise, vérification du niveau d’huile et vérification de la pression des pneus.<br>
                      -	Informations sur l’utilisation des produits <br>
                      - <b>Superettes</b> : Les stations DISTRICOM OIL vous proposent des superettes et supermarchés où vous trouverez une large gamme de produits de grande consommation (hygiène, alimentaires, cosmétiques, …) dans un cadre agréable. Nous faisons en sorte que la qualité et la diversité des produits proposés dans nos boutiques répondent aux attentes de la clientèle.
                    </p>
                </div>
            </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination,Autoplay, Scrollbar,A11y  } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Pagination, Autoplay,Scrollbar,A11y ]);
export default {
  mounted(){
      setTimeout(()=>{
          new Swiper('.swiper-container.stations', {
              // Optional parameters
              autoplay: {
                  delay: 5000,
              },
              speed: 500,
              direction: 'horizontal',
              loop: true,
              // If we need pagination
              pagination: {
                  el: '.swiper-pagination',
              },

              // Navigation arrows
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
              },

              // And if we need scrollbar
              scrollbar: {
                  el: '.swiper-scrollbar',
              },
          });
    },2000);
  }
}
</script>

<style scoped lang="scss">
    
    .swiper-container {
      width: 100%;
      height: 300px;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      img{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    }
</style>