var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"dg shadow-sm"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 p-0"},[_c('div',{staticClass:"box"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"options":{
                                            zoomControl: true,
                                            mapTypeControl: true,
                                            scaleControl: true,
                                            streetViewControl: true,
                                            rotateControl: true,
                                            fullscreenControl: true,
                                            disableDefaultUi: false
                                        },"center":{
                                            lat: 6.7960670,
                                            lng: -5.2794310
                                        },"zoom":7}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true,"icon":_vm.markerOptions},on:{"click":function($event){_vm.center=m.position}}})}),1)],1)]),_c('div',{staticClass:"col-sm-6 border-bottom p-0",staticStyle:{"background":"#0b2239"}},[_c('div',{staticClass:"box p-4 text-white",staticStyle:{"background":"#0b2239"}},[_c('h1',{staticClass:"mb-3"},[_vm._v("Implantation")]),(_vm.cities)?_c('div',[_c('p',{staticStyle:{"font-size":"17px"}},[_vm._v(" Nous sommes présents dans "),_c('b',[_vm._v(_vm._s(_vm.cities.city.length))]),_vm._v(" villes de la Côte d'Ivoire, avec plus de "),_c('b',[_vm._v(_vm._s(_vm.cities.list.length))]),_vm._v(" stations ... ")]),_c('div',{staticClass:"swiper-container imp"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.cities.city),function(item){return _c('div',{key:item.id,staticClass:"swiper-slide"},[_c('h1',{staticStyle:{"color":"#F00","text-transform":"uppercase"}},[_vm._v(_vm._s(item.ville))])])}),0)])]):_vm._e()])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }