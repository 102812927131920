<template>
    <div>
        <!-- <div class="box-img-flyer">
            <img src="../../../assets/banner.jpg" alt="">
            <div class="absolute-title text-white">
            <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                    <h1 class="p-3">DISTRICOM OIL</h1>
            </div>
            </div>
        </div> -->
        <div class="col-sm-8 offset-sm-2 p-0 shadow-sm mt-5 mb-5" style="font-size:16px;text-align : justify">
            <p class="border-bottom"><img class="w-100" src="../../../assets/btp.jpg" alt=""></p>
            <div class="p-3">
                <h6 class="text-blue font-weight-bold">Confiez vos projets de construction de station-service à DISTRICOM. </h6>
                <p>Notre expérience de plus de 20 ans dans la construction de stations-services constitue pour vous, une assurance pour nous confier vos projets de construction de station-service à DISTRICOM, d’installation d’équipement de stockage et de distribution de carburant ou gaz.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>