<template>
    <div class="partenaire pb-5" style="background:#eee">
        <div class="p-3">
            <div class="col-sm-12 text-center mt-5">
                <h1>Nos partenaires</h1>
                <p>Ils nous font confiance</p>
            </div>


            <!-- <div class="container"> -->
                <!-- <div class="col-sm-12">
                    <div class="row">
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                        <a  href="http://www.gestoci.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/24-021.png" height="60" alt=""></a> 
                                </div>
                        </div>
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                        <a  href="http://www.smb.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/smb-02.png" height="60" alt=""></a> 
                                </div>
                        </div>
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                         <a  href="http://www.petroci.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/petro-02.png" height="60" alt=""></a> 
                                </div>
                        </div>

                         <div class="col-sm-3">
                                <div class="swiper-slide">
                         <a  href="http://www.presticom-ci.com" target="_blank"> <img src="../../assets/presticom.png" height="60" alt=""></a> 
                                </div>
                        </div>
                         <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.groupesoroubat.com" target="_blank"> <img src="../../assets/logo_soroubat.png" height="60" alt=""></a> 
                                </div>
                        </div>

                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.dgh.ci" target="_blank"> <img src="../../assets/logo-dgh.png" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.sudcontractors.com" target="_blank"> <img src="../../assets/logo_sudcontractors.png" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.cocitam.com" target="_blank"> <img src="../../assets/logo_cocitam.png" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.globalservicesci.com" target="_blank"> <img src="../../assets/logo-global.jpg" height="60" alt=""></a> 
                                </div>
                        </div>
                        <div class="col-sm-3">
                                <div class="swiper-slide">
                                    <a  href="http://www.mpeder.ci" target="_blank"> <img src="../../assets/mine.jpg" height="60" alt=""></a> 
                                </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="swiper-container partner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                         <a  href="http://www.presticom-ci.com" target="_blank"> <img src="../../assets/presticom.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.mtn.ci" target="_blank"> <img src="../../assets/mtn-logo.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.banqueatlantique.net" target="_blank"> <img src="../../assets/ba.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.sib.ci" target="_blank"> <img src="../../assets/sib.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.banquebni.net" target="_blank"> <img src="../../assets/bni.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.groupensia.com" target="_blank"> <img src="../../assets/NSIA-Banque.jpg" height="60" alt=""></a> 
                    </div>
                     <div class="swiper-slide">
                         <a  href="http://www.groupensia.com" target="_blank"> <img src="../../assets/NSIA-Assurance-Banque.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.sahamassurance.ci" target="_blank"> <img src="../../assets/saham.jpg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.brinks.fr" target="_blank"> <img src="../../assets/brinks.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.cnps.ci" target="_blank"> <img src="../../assets/cnps.jpeg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://agenceemploijeunes.ci" target="_blank"> <img src="../../assets/Logo-AEJ.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.banquepopulaire.ci" target="_blank"> <img src="../../assets/bp.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.cnps.ci" target="_blank"> <img src="../../assets/cnps.jpeg" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                         <a  href="http://www.societegenerale.ci" target="_blank"> <img src="../../assets/sgbci.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <a  href="http://www.groupesoroubat.com" target="_blank"> <img src="../../assets/logo_soroubat.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                                    <a  href="http://www.dgh.ci" target="_blank"> <img src="../../assets/logo-dgh.png" height="60" alt=""></a> 
                                </div>
                    <div class="swiper-slide">
                                    <a  href="http://www.sudcontractors.com" target="_blank"> <img src="../../assets/logo_sudcontractors.png" height="60" alt=""></a> 
                                </div>
                    <div class="swiper-slide">
                                    <a  href="http://www.globalservicesci.com" target="_blank"> <img src="../../assets/logo-global.jpg" height="60" alt=""></a> 
                                </div>
                    <div class="swiper-slide">
                        <a  href="http://www.gestoci.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/24-021.png" height="60" alt=""></a> 
                    </div>
                    <div class="swiper-slide">
                        <a  href="http://www.smb.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/smb-02.png" height="60" alt=""></a> 
                    </div>
                                <div class="swiper-slide">
                         <a  href="http://www.petroci.ci" target="_blank"> <img src="https://districom.ci/images/2020/07/05/petro-02.png" height="60" alt=""></a> 
                                </div>
                                <div class="swiper-slide">
                                    <a  href="http://www.mpeder.ci" target="_blank"> <img src="../../assets/mine.jpg" height="60" alt=""></a> 
                                </div>
                    
                </div>

                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, Navigation, Pagination,Autoplay, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Pagination,Autoplay, Scrollbar]);
export default {
    name:'Partenaire',
    mixins : [],
    mounted() { 
        new Swiper('.swiper-container.partner', {
            // Optional parameters
            autoplay: {
                delay: 5000,
            },
            speed: 500,
            slidesPerView: 4,
            slidesPerColumn: 2,
            spaceBetween: 10,
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination'
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // And if we need scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
    }
}
</script>
<style scoped>
    .swiper-container {
      width: 100%;
      height: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: calc((100% - 30px) / 2);

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
</style>