<template>
  <div style="font-size:16px">
        <h4 class="text-blue"><b>Présentation</b></h4>
        <p class="mt-4">
            DISTRICOM est un fournisseur indépendant de produits, services pétroliers et gaziers en Côte d'Ivoire. Les produits que nous distribuons répondent aux besoins des particuliers et des entreprises.
        </p>
        <p>Créé le 23 octobre 2009 DISTRICOM "Société de Distribution et de Commerce" a pour objet la distribution et le Transport de produits pétroliers dont le siège social est à Yamoussoukro, quartier Dioulakro, route d’Oumé.</p>

        <p>Au cours des années, nous avons su nous adapter pour répondre à l’évolution des besoins en énergie pour être agréé distributeur de produits pétroliers en Côte d'Ivoire. 
        </p>    
        <p>
            Nous disposons d'une logistique efficace et d'un vaste réseau de distribution incluant nos propres stations-services de détail pour créer la proximité avec nos clients et répondre à leur besoin.
        </p>
        <p>
            Notre réputation de partenaire commercial de qualité repose sur un modèle alliant professionnalisme et maîtrise de la chaine d’acquisition et de distribution des produits pétroliers et gaziers à destination des partenaires et consommateurs finaux.
        </p>
        <p>
            Nous proposons une large gamme de produits et services incluant carburants, GPL (gaz de pétrole liquéfié) et lubrifiants.
        </p>
        <p>
            <i><b>Notre ambition :</b> Être l’un, sinon, le principal acteur de la distribution des produits et services pétroliers et gaziers en Côte d'ivoire. </i>
        </p>
        <p>
            <b>DISTRICOM</b> résume l'esprit d'une entreprise citoyenne ivoirienne dont les produits et services répondent aux exigences de qualité et de normes internationales de sécurité. 
        </p>
        <p>
            <b>DISTRICOM OIL & GAZ</b> est une marque créée par des ivoiriens, pour les populations vivant en Côte d'Ivoire.
        </p>

        <h4 class="text-blue mt-5"><b>Valeurs & vision</b></h4>
        <p class="mt-4">
                DISTRICOM vise à être l'une des compagnies pétrolières les plus respectées en Côte d'Ivoire. 
        </p>
        <p>
            Notre objectif immédiat est de gagner le respect de la population ivoirienne grâce à notre connaissance approfondie du pays, à notre capacité à répondre aux besoins énergétiques de nos clients. 
        </p>
        <p>
            Nous avons l’intention de poursuivre l’expansion de notre réseau afin d’assurer à nos clients, tant particuliers qu’industriels, l’accès aux produits pétroliers et gaziers dont ils ont besoin.
        </p>
        <p>
            Nous intensifions notre soutien au développement socio-économique de la Côte d'ivoire et participons activement à réduire les enjeux écologiques et sanitaires que
            pose l’usage, encore répandu, du bois de chauffage, du charbon de bois comme sources d’énergie au quotidien.                
        </p>
        <h4 class="text-blue mt-5"><b>Stratégie</b></h4>
        <p class="mt-4">Notre stratégie est bâtie autour de trois (4) indicateurs clés : </p>
        <p class="font-weight-bold pl-4">1. Produits</p>
        <p>DISTRICOM travaille à ce que ses produits soient de qualité supérieure et permanemment reconnus de tous les consommateurs. </p>
        <p class="font-weight-bold pl-4">2. Prix</p>
        <p>En dehors des prix homologués par la tutelle, DISTRICOM a pour tradition de proposer à sa clientèle, une politique de prix alliant qualité et coût compétitifs. </p>

        <p class="font-weight-bold pl-4">3. Distribution</p>
        <p>
            Pour être proche de sa clientèle, DISTRICOM a mis en place un vaste réseau de distribution à travers plusieurs villes du pays et s’appuyant sur des points de vente de particuliers et sur l’ensemble de son réseau de stations-services et de points de vente gaz. 
        </p>
        <p class="font-weight-bold pl-4">4. Promotion</p>
        <p>
            Construire une marque de qualité et un label de notoriété ; tel est le défi que s’est octroyé DISTRICOM.
            Pour ce faire, elle a opté pour une stratégie d’intensification et de diversification des canaux de communication. 
        </p>

    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>