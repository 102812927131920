<template>
  <div style="font-size:16px">
      <h4 class="text-blue"><b>Distribution</b></h4>
      <p>Chaque jour, ce sont des millions d'entreprises et de personnes qui consomment du carburant (super, gasoil), du pétrole et du gaz pour se déplacer, travailler, se chauffer, cuisiner… Nous achetons et vendons ces produits à travers toute la Côte d’Ivoire. </p>
      <p>Depuis plus d’une vingtaine d’année, DISTRICOM intervient dans la commercialisation de produits pétroliers et gaziers sur toute l’étendue du territoire national.</p>
      <h4 class="text-blue mt-5"><b>Transport de produits pétrolier</b></h4>
      <p>En plus de la distribution, nous intervenons aussi dans le transport de produits pétroliers ou gaziers pour le compte d’autres entreprises. Nous disposons d’un important parc automobile qui nous permet de satisfaire chaque jour, nos clients professionnels.</p>
      <h4 class="text-blue mt-5"><b>BTP</b></h4>
      <p>DISTRICOM intervient également dans le domaine du bâtiment pour mener à bien tous vos projets de construction, rénovation, extension de stations-services et autres types de bâtiment. A ce titre, nous intervenons dans l’installation d’équipements de stockage et de distribution (cuve à gaz, citerne à carburant) sur vos différents sites exploitation.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>