<template>
  <div style="font-size:16px">
      <h4 class="text-blue"><b>Développement et soutien aux communautés locales</b></h4>
      <p>DISTRICOM est résolument engagé à devenir un acteur de référence incontournable dans la distribution et la commercialisation de produits pétroliers et gaziers sur toute l’étendue du territoire national.</p>
      <p>DISTRICOM est résolument engagé à devenir un acteur de référence incontournable dans la distribution et la commercialisation de produits pétroliers et gaziers sur toute l’étendue du territoire national tout en respectant les lois applicables et les règles d'éthique.</p>
      <p>
        Nous sommes engagés aux côtés des populations en vue d’impulser un développement local des collectivités, à travers notre fondation.
      </p>
      <p><a href="https://fondation.districom.ci" target="_blank"><b>Visitez le site de la Fondation DISTRICOM</b></a></p>
      <h4 class="text-blue mt-5"><b>Hygiène, santé et sécurité</b></h4>
      <p>Soucieuse du bien-être de son personnel, de ses partenaires, de ses clients et de la protection de l’environnement, DISTRICOM se dote d’une politique d’hygiène, de sécurité, de santé et environnement au travail pour son activité dans le secteur de l’hydrocarbure.</p>
      <p>De cette manière, notre objectif est de gagner la conﬁance de nos clients, de nos actionnaires et partenaires ainsi que du grand public et de contribuer à la protection de l’environnement et au développement durable. </p>
      <p>Dans cette optique, nous nous engageons à : </p>
      <p>
        <ul>
          <li>Poursuivre notre but tout en protégeant notre personnel, nos partenaires et l’environnement</li>
          <li>Utiliser le matériel adapté pour livrer nos produits et assurer nos services</li>
          <li>Publier un rapport de nos performances en matière d’hygiène, de sécurité, de santé et environnement</li>
          <li>Jouer un rôle majeur dans la promotion des meilleures pratiques dans notre secteur d’activité</li>
          <li>Promouvoir une culture amenant tous les collaborateurs, le personnel, les clients de DISTRICOM à adhérer à cet engagement</li>
        </ul>
      </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>