<template>
  <div>
     <!-- background:#FFF !important;-webkit-box-shadow: 0 0 1px rgba(0,0,0,.14),0 1px 3px rgba(0,0,0,.28) !important;box-shadow: 0 0 1px rgba(0,0,0,.14),0 1px 1px rgba(0,0,0,.28) !important; -->
        <nav class="navbar fixed-top navbar-expand-lg shadow-sm" style="background:#fff !important">
            <div class="text-center m-0 details-asfa" style="position:absolute;z-index:9990;top:0;left:0;right:0;height:auto;background:#26388d;color:#FFF">
               <div class="d-flex justify-content-around p-2" style="font-size:13px">
                    <span class="pl-2"><img src="../assets/calendar.png" class="float-left mr-2" style="height:20px;" alt=""> {{date | moment}} </span>
                    <div class="swiper-container price"> 
                      <div class="swiper-wrapper">                                          
                          <div class="swiper-slide">
                              Gazoil : 600 Fcfa
                          </div>
                          <div class="swiper-slide">
                             Super sans plomb : 600 Fcfa
                          </div>
                          <div class="swiper-slide">
                              Pétrole : 555 Fcfa
                          </div>
                          <div class="swiper-slide">
                             Gaz B6 : 2.000 Fcfa
                          </div>
                          <div class="swiper-slide">
                             Gaz B12 : 5.200 Fcfa
                          </div>
                          <div class="swiper-slide">
                            Gaz B38 : 18.480 Fcfa
                          </div>
                      </div>
                    </div>
                    
                    <div class="d-flex border-left pl-2 mr-3">
                        <span>Suivez-nous sur </span>
                        <!-- <div class="flex ml-4"><img height="20" class="rounded-circle" src="../assets/logo-linkedin-294x294.png" alt=""></div> -->
                        <div class="flex ml-2"><a target="_blank" href="https://facebook.com/DistricomYamoussoukro"><img height="20" class="rounded-circle" src="../assets/facebook.png" alt=""></a></div>
                        <div class="flex ml-2"><a target="_blank" href="https://twitter.com/districom_ci"><img height="20" class="rounded-circle" src="../assets/twitter.png" alt=""></a></div>
                        <div class="flex ml-2"><a href="https://www.instagram.com/districom_ci"><img height="25" class="rounded-circle" src="../assets/instagram.png" alt=""></a></div>
                    </div>
               </div>
            </div>
           <div class="container" style="margin-top:35px">
           <a class="navbar-brand text-center" href="#">
             <div class="d-flex">
                <a href="/districo/home"><img src="../assets/logo.png" height="55px" alt=""> <br>  </a>        
             </div>
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img" focusable="false"><title>Menu</title><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
             <ul class="navbar-nav navbar-top mr-auto text-center">
                <li class="nav-item">
                  <a href="" class="nav-link"></a>
                </li>
            </ul>
            <ul class="navbar-nav navbar-top">
              <li class="nav-item">
                <router-link :to="{ name : 'home'}" class="nav-link">
                    Accueil
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <a id="dropdownMenuButton" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">
                    Notre Groupe
                </a>
                 <div class="dropdown-menu shadow-lg p-0" style="border:0;width:auto" aria-labelledby="dropdownMenuButton">
                  <div class="d-flex">
                        <div style="width:200px;height:200px">
                          <img src="../assets/bg3.jpg" style="height:100%;width:100%;object-fit:cover" alt="">
                        </div>
                        <div>
                            <router-link :to="{ name : 'about'}" class="dropdown-item">
                                Qui sommes-nous ?
                            </router-link>
                            <router-link :to="{ name : 'contact-us'}" class="dropdown-item">
                                Nous contacter
                            </router-link>
                        </div>
                    </div>
                </div>
              </li>
              <li class="nav-item dropdown">
                <router-link :to="{ name : 'prestations'}" class="nav-link">
                    Nos prestations
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'stations'}" class="nav-link">
                    Nos stations
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'job'}" class="nav-link">
                    Carrières
                </router-link>
              </li>
               <li class="nav-item">
                <router-link :to="{ name : 'conseils'}" class="nav-link">
                    Blog
                </router-link>
              </li>
              <router-link class="nav-link" :to="{ name : 'gas'}">
                      <img src="../assets/gas.png" class="float-left" style="height:20px;" alt="">Ouvrir un dépôt de GAZ
              </router-link>
              <li class="nav-item ml-2" style="background:#26388d;border-radius:60px">
                <a target="_blank" style="color:#FFF" href="https://fondation.districom.ci" class="nav-link">
                   <img src="../assets/icon5.png" class="float-left mr-2" style="height:20px;" alt=""> Fondation DISTRICOM
                </a>
              </li>
            </ul>
          </div>
        </div>
        </nav>
        <div style="margin-top:115px;">
          <!-- <div style="height:80px;width:100%;background:#1160c7">
              <img src="../assets/banner.jpg" style="width:100%;height:100%;object-fit:contain" alt="">
          </div> -->
          <!-- <keep-alive> -->
              <router-view></router-view>
          <!-- </keep-alive> -->
          <Footer></Footer>
        </div>
        <button type="button" @click="topFunction" id="arrowup">
          <font-awesome-icon style="font-size:50px;color:#FFF" icon="arrow-up"/>
        </button>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination,Autoplay, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Pagination,Autoplay, Scrollbar]);
import Footer from '../components/layouts/Footer.vue'
import moment from 'moment';
import * as $ from 'jquery'
function scrollFunction() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        $('#arrowup').css('display',"block");
      } else {
         $('#arrowup').css('display',"none");
      }
}
export default {
  mixins : [],
  components: {
    Footer,
  },
  data(){
    return {
      date : new Date()
    }
  },
  computed : {
    location(){
      return window.location.pathname
    }
  },
  mounted(){
      window.onscroll = function() {scrollFunction()};
      document.title = "DISTRICOM - Société de Distribution et de Commerce."
      setTimeout(()=>{
          moment.locale('fr');
          moment().format('L');
      },5000);
      new Swiper('.swiper-container.price', {
          // Optional parameters
          autoplay: {
              delay: 3000,
          },
          speed: 500,
          slidesPerView: 1,
          spaceBetween: 10,
          direction: 'vertical',
          loop: true,
      });
  },
  methods : {
    // localesRedirect(){
    //     this.$router.push({name : this.$route.name, params : { locales : this.$route.params.locales == 'fr' ? 'en' : 'fr'}});
    //     location.reload(true);
    // }
      topFunction() {
        // document.body.scrollTop = 0;
        $('html, body').animate({scrollTop:0},500);
        // document.documentElement.scrollTop = 0;
      }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD MMM YYYY');
    }
  }
}
</script>

<style scoped lang="scss">
    .swiper-container {
      width: auto;
      height: 20px;
    }
    .btn-to-top {
      width: 60px;
      height: 60px;
      padding: 10px 16px;
      border-radius: 50%;
      font-size: 22px;
      line-height: 22px;
      color:#FFF;
    }

    #arrowup{
    display: block;
    position: fixed;
    height : 60px;
    width : 60px;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #26388d;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
  }

  #myBtn:hover {
    background-color: #fcb813;
  }
</style>