<template>
  <div>
    <div class="box-img-flyer">
        <img src="../assets/bonon.jpg" alt="">
        <div class="absolute-title text-yellow">
          <div style="width:100%;height:100%;background:rgba(0,0,0,0.6)">
                <h1>Notre groupe !</h1>
                <h1>Découvrez notre compagnie ...</h1>
          </div>
        </div>
    </div>
    <div class="col-sm-12 mt-5 mb-5">
        <div class="row">
                <div class="col-3">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">A propos de nous !</a>
                    <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Notre force</a>
                    <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Notre expertise</a>
                    <a class="nav-link" id="v-pills-eng-tab" data-toggle="pill" href="#v-pills-eng" role="tab" aria-controls="v-pills-eng" aria-selected="false">Notre engagement</a>
                    </div>
                </div>
                <div class="col-9 border-left">
                    <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <About></About>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                       <Force></Force>
                    </div>
                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                      <Expertise></Expertise>
                    </div>
                     <div class="tab-pane fade" id="v-pills-eng" role="tabpanel" aria-labelledby="v-pills-eng-tab">
                      <Engagement></Engagement>
                    </div>
                    </div>
                </div>
            </div>
    </div>
  </div>
</template>
<script>
import About from './layouts/about/About.vue'
import Expertise from './layouts/about/Expertise.vue'
import Force from './layouts/about/Force.vue'
import Engagement from './layouts/about/Engagement.vue'
export default {
    components : {
        About,
        Expertise,
        Force,
        Engagement
    }
}
</script>

<style>

</style>