<template>
    <div class="mt-5 container">
        <div class="col-sm-12 text-center">
            <div class="row">
                <div class="col-sm-12 p-2 mb-3">
                    <h1>Chiffres clés</h1>
                    <p>Découvrez DISTRICOM en quelques chiffres</p>
                </div>
                <div class="col-sm-12 estimation p-3">
                    <div class="d-flex justify-content-around">
                        <div>
                            <p><font-awesome-icon style="font-size:80px;color:#fdd835" icon="pump-soap"/></p>
                            <p class="title-p">
                                02
                            </p>
                            <p class="m-0">Centre emplisseur GAZ</p>
                        </div>
                        <div >
                             <p><font-awesome-icon style="font-size:80px;color:#fdd835" icon="gas-pump"/></p>
                            <p class="title-p">
                                14
                            </p>
                            <p class="m-0">Stations</p>
                        </div>
                        <div>
                            <p><font-awesome-icon style="font-size:80px;color:#fdd835" icon="store-alt"/></p>
                            <p class="title-p">
                                600+
                            </p>
                            <p class="m-0">Point de vente de GAZ</p>
                        </div>
                        <div>
                            <p><font-awesome-icon style="font-size:80px;color:#fdd835" icon="people-carry"/></p>
                            <p class="title-p">
                                400+
                            </p>
                            <p class="m-0">Collaborateurs</p>
                        </div>
                        <div >
                            <p><font-awesome-icon style="font-size:80px;color:#fdd835" icon="truck-moving"/></p>
                            <p class="title-p">
                                30+
                            </p>
                            <p class="m-0">Flotte automobile</p>
                        </div>
                        <!-- <div >
                            <p class="title-p">
                                1M+
                            </p>
                            <p class="m-0">Capacité de stockage</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Estimation',
  mixins : [],
  props: {}
}
</script>

<style scoped>
    .estimation{
        background: #fff;
        min-height:100px;
        color:#000;
    }

     .estimation .title-p{
            font-weight: 900;
            font-size: 3rem;
            color:#007DFF;
     }
</style>